import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBAnimation, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import AltList from '../components/altList'
import TimelineSection from '../components/timelineSection'

const About = ({ data, location }) => {
  const post = data.markdownRemark
  const sortedTimeline = post.frontmatter.timeline.sort((a, b) => a.date == b.date ? 0 : a.date > b.date ? -1 : 1)

  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
      <section className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                {post.html != null && (
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section &&
          <section className="bg-white">
            <MDBContainer>
              <div>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <AltList
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium text-very-dark-blue"
                      bullets={sections.bullets}
                      link={sections.btnlink}
                      colour={sections.btncolour}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }

        {post.frontmatter.timeline &&
          <section className="timeline-area">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <MDBAnimation reveal type="fadeInUp" duration="1400ms">
                    <h2 className="text-center font-alt font-w-700 letter-spacing-1 title-xs-medium title-large-3 mb-5">
                      {post.frontmatter.timelinetitle}
                    </h2>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <div className="timeline-main">
                    <MDBAnimation reveal type="fadeInUp" duration="1200ms">
                      <ul className="stepper stepper-vertical timeline timeline-basic pl-0 m-0">
                        {sortedTimeline.map((timelines, index) => {
                          return (
                            <TimelineSection
                              key={index}
                              id={index}
                              position="left"
                              date={timelines.date}
                              description={timelines.description}
                              website={timelines.website}
                              pressRelease={timelines.pressrelease}
                              faicon={timelines.faicon}
                              circleColour={timelines.circlecolour}
                            />
                          )
                        })}
                      </ul>
                    </MDBAnimation>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        }
      </main>
    </Layout>
  )
}
export default About 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "About" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90) 
            }
          }
          placement
          alttext
          bullets {
            text
          }
        }
        timelinetitle
        timeline {
          date
          description
          website
          pressrelease
          faicon
          circlecolour
        }
      }
      html
    }
  }
`