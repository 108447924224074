import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faMegaphone, faHandshake, faFileCertificate, faBuilding, faAward, faPlaneDeparture, faHotel,
  faCity, faCloudUploadAlt, faWineGlassAlt, faTrophy, faBrain, faGrinStars, faRocketLaunch } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'

class timelineSection extends React.Component {
  render() {
    return (
      <>
        <li
          key={this.props.id}
          className={[`${this.props.id % 2 ? '' : 'timeline-inverted'}`]}
        >
          <a href={this.props.pressRelease} target="_blank" className="cursor-none">
            <span
              className={[`circle z-depth-1-half ${this.props.circleColour}`]}
            >
              {this.props.faicon == 'handshake' && (
                <FontAwesomeIcon icon={faHandshake} />
              )}

              {this.props.faicon == 'file-certificate' && (
                <FontAwesomeIcon icon={faFileCertificate} />
              )}

              {this.props.faicon == 'building' && (
                <FontAwesomeIcon icon={faBuilding} />
              )}

              {this.props.faicon == 'award' && (
                <FontAwesomeIcon icon={faAward} />
              )}

              {this.props.faicon == 'plane-departure' && (
                <FontAwesomeIcon icon={faPlaneDeparture} />
              )}

              {this.props.faicon == 'hotel' && (
                <FontAwesomeIcon icon={faHotel} />
              )}

              {this.props.faicon == 'city' && <FontAwesomeIcon icon={faCity} />}

              {this.props.faicon == 'cloud-upload-alt' && (
                <FontAwesomeIcon icon={faCloudUploadAlt} />
              )}

              {this.props.faicon == 'wine-glass-alt' && (
                <FontAwesomeIcon icon={faWineGlassAlt} />
              )}

              {this.props.faicon == 'trophy' && (
                <FontAwesomeIcon icon={faTrophy} />
              )}

              {this.props.faicon == 'brain' && (
                <FontAwesomeIcon icon={faBrain} />
              )}

              {this.props.faicon == 'grin-stars' && (
                <FontAwesomeIcon icon={faGrinStars} />
              )}

              {this.props.faicon == 'rocket-launch' && (
                <FontAwesomeIcon icon={faRocketLaunch} />
              )}

            </span>
          </a>

          <div className="step-content z-depth-1 ml-2 p-4 card-hover">
            <h3 className="d-inline-block font-alt font-w-700 letter-spacing-1 title-xs-small title-medium mr-4">
              {moment(this.props.date).format('MMMM YYYY')}
            </h3>
            {this.props.website && (
              <div className="d-inline-block mr-1">
                {this.props.website && (
                  <a className="url" href={this.props.website} target="_blank">
                    <FontAwesomeIcon icon={faLink} />
                  </a>
                )}
              </div>
            )}
            {this.props.pressRelease && (
              <div className="d-inline-block">
                {this.props.pressRelease && (
                  <a
                    className="url"
                    href={this.props.pressRelease}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faMegaphone} />
                  </a>
                )}
              </div>
            )}
            <p className="font-w-400 text-xs-medium text-medium mb-0">
              {this.props.description}
            </p>
          </div>
        </li>
      </>
    )
  }
}

export default timelineSection